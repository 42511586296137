<template>
  <div ref="body" @click="closeSelectList">
    <custom-header active="service" :subActive="current"></custom-header>

    <div class="content-container-form ">
      <h1>Submit Sourcing Request</h1>
      <div class="tips text-red">* Mandatory fields</div>
      
      <!-- looking -->
      <div class="section">
        <div class="title">
          <span>I am looking for (Multiple selections allowed)</span>
          <span class="text-red">*</span>
        </div>
        <div class="" :class="[error.looking ? 'border-red' : 'border-white']" style="padding-left: 5px;">
          <el-checkbox-group v-model="looking" @change="onChange">
            <el-checkbox label="Furniture">Furniture</el-checkbox>
            <el-checkbox label="Decor">Décor</el-checkbox>
            <el-checkbox label="Smart">Smart</el-checkbox>
          </el-checkbox-group>
        </div>
        <span v-if="error.looking" class="error-tips text-red">Please select</span>
      </div>
      
      <!-- 家具 Furniture -->
      <div v-if="isShowFurniture" class="section">
        <div class="title">
          <span>Product Category (multiple selections allowed)</span>
          <span class="text-red">*</span>
        </div>
        <div class="" :class="[error.checked1 ? 'border-red' : 'border-white']" style="padding-left: 5px;">
          <el-checkbox-group v-model="checked1" @change="onChange1">
            <el-checkbox label="Living"></el-checkbox>
            <el-checkbox label="Bedroom"></el-checkbox>
            <el-checkbox label="Dining"></el-checkbox>
            <el-checkbox label="Customized"></el-checkbox>
            <el-checkbox label="Original"></el-checkbox>
            <el-checkbox label="Design"></el-checkbox>
            <el-checkbox label="Kids"></el-checkbox>
          </el-checkbox-group>
          <div class="other-box">
            <span>Others：pls clarify</span>
            <input type="text" v-model="plsClarify1" class="other-input" @blur="onChange1">
          </div>
        </div>
        <span v-if="error.checked1" class="error-tips text-red">Please select</span>
      </div>
      
      <!-- 装饰 Decor -->
      <div v-if="isShowDecor" class="section">
        <div class="title">
          <span>Product Category (multiple selections allowed)</span>
          <span class="text-red">*</span>
        </div>
        <div class="" :class="[error.checked2 ? 'border-red' : 'border-white']" style="padding-left: 5px;">
          <el-checkbox-group v-model="checked2" @change="onChange2">
            <el-checkbox label="Lifestyle"></el-checkbox>
            <el-checkbox label="art"></el-checkbox>
            <el-checkbox label="accessories"></el-checkbox>
          </el-checkbox-group>
          <div class="other-box">
            <span>Others：pls clarify</span>
            <input type="text" v-model="plsClarify2" class="other-input" @blur="onChange2">
          </div>
        </div>
        <span v-if="error.checked2" class="error-tips text-red">Please select</span>
      </div>
      
      <!-- 智能化 Smart -->
      <div v-if="isShowSmart" class="section">
        <div class="title">
          <span>Product Category (multiple selections allowed)</span>
          <span class="text-red">*</span>
        </div>
        <div class="" :class="[error.checked3 ? 'border-red' : 'border-white']">
          <el-checkbox-group v-model="checked3" @change="onChange3" class="checked3">
            <el-checkbox label="Whole-house"></el-checkbox>
            <el-checkbox label="smart solutions"></el-checkbox>
            <el-checkbox label="smart control system"></el-checkbox>
            <el-checkbox label="smart kitchen"></el-checkbox>
            <el-checkbox label="smart sleep"></el-checkbox>
            <el-checkbox label="smart bathroom"></el-checkbox>
            <el-checkbox label="smart lighting"></el-checkbox>
          </el-checkbox-group>
          <div class="other-box">
            <span>Others：pls clarify</span>
            <input type="text" v-model="plsClarify3" class="other-input" @input="onChange3" @blur="onChange3">
          </div>
        </div>
        <span v-if="error.checked3" class="error-tips text-red">Please select</span>
      </div>
      
      <!-- 采购目的 Sourcing Purpose -->
      <div class="section">
        <div class="title">Sourcing Purpose</div>
        <div class="sub-title">
          <span>The nature of this sourcing request</span>
          <span class="text-red">*</span>
        </div>
        <div class="">
          <textarea v-model="form.sourcingPurpose" class="textarea" :class="[errorTip.sourcingPurpose ? 'border-red' : '']" @focus="validErr('sourcingPurpose')" @blur="validErr('sourcingPurpose')" style="padding-left: 5px;"></textarea>
          <span v-if="errorTip.sourcingPurpose" class="error-tips text-red">Please input</span>
        </div>
      </div>
      
      <!-- contact Info -->
      <div class="section">
        <div class="title">My contact Info</div>
        
        <!-- 称呼、姓名 -->
        <div class="row">
          <div class="salutation ">
            <div class="option relative">
              <input type="text" v-model="form.sex" readonly placeholder="" class="input sex-input hand" @focus="validErr('sex')" @blur="validErr('sex')" @click.stop="showSexList" :class="[errorTip.sex ? 'border-red' : '']">
              <span class="trun-icon absolute hand" @click.stop="showSexList">&gt;</span>
              <div class="label absolute">Salutation <span class="text-red">*</span></div>
              <div v-if="isShowSexList" class="sex-list absolute">
                <div class="sex-option"> -- Please select --</div>
                <div class="sex-option hover" @click.stop="sexChange('MR')">MR</div>
                <div class="sex-option hover" @click.stop="sexChange('MS')">MS</div>
              </div>
            </div>
            <span v-if="errorTip.sex" class="error-tips text-red">Please select</span>
          </div>
          <div class="name option relative">
            <div class="option">
              <input type="text" v-model.trim="form.name" class="input" @focus="validErr('name')" @blur="validErr('name')" :class="[errorTip.name ? 'border-red' : '']">
              <div class="label absolute">Name <span class="text-red">*</span></div>
            </div>
            <span v-if="errorTip.name" class="error-tips text-red">Please input</span>
          </div>
        </div>
        
        <!-- 公司名称 -->
        <div class="row">
          <div class="company-name option relative">
            <div class="option">
              <input type="text" v-model.trim="form.companyName" class="input" @focus="validErr('companyName')" @blur="validErr('companyName')" :class="[errorTip.companyName ? 'border-red' : '']">
              <div class="label absolute">Company Name <span class="text-red">*</span></div>
            </div>
            <span v-if="errorTip.companyName" class="error-tips text-red">Please input</span>
          </div>
        </div>
        
        <!-- 国家 -->
        <div class="row">
          <div class="country-name option relative">
            <div class="option">
              <input type="text" v-model="form.country" readonly placeholder="" class="input hand" @click.stop="showCountryList" @focus="validErr('country')" @blur="validErr('country')" :class="[errorTip.country ? 'border-red' : '']">
              <span class="trun-icon absolute hand" @click.stop="showCountryList">&gt;</span>
              <div class="label absolute">Country / Region <span class="text-red">*</span></div>
              <div v-if="isShowCountryList" class="country-list-box absolute top" :class="[isTop ? 'top' : 'down']">
                <input type="text" v-model="keyword" class="search-input" @input="inputSearch" @click.stop>
                <div class="country-list" @click="chooseLocation">
                  <div v-for="country in locationOptions" :key="country.id" :data-name="country.area_name" :data-code="country.national_code" class="country-option">{{country.area_name}}</div>
                </div>
              </div>
            </div>
            <span v-if="errorTip.country" class="error-tips text-red">Please select</span>
          </div>
        </div>
        
        <!-- 国家区号、电话 -->
        <div class="row">
          <div class="country-code relative">
            <div class="option">
              <input type="text" v-model.trim="form.countryCode" placeholder="" class="input left" @focus="validErr('countryCode')" @blur="validErr('countryCode')" :class="[errorTip.countryCode ? 'border-red' : '']">
              <div class="label absolute">Country / Region Code <span class="text-red">*</span></div>
            </div>
            <span v-if="errorTip.countryCode" class="error-tips text-red">Please input</span>
          </div>
          <div class="area-code option relative">
            <div class="option">
              <input type="text" v-model="form.areaCode" class="input center">
              <div class="label absolute">Area Code</div>
            </div>
          </div>
          <div class="tel relative">
            <div class="option">
              <input type="text" v-model.trim="form.tel" placeholder="" class="input center" @focus="validErr('tel')" @blur="validErr('tel')" :class="[errorTip.tel ? 'border-red' : '']">
              <div class="label absolute">Tel .No <span class="text-red">*</span></div>
            </div>
            <span v-if="errorTip.tel" class="error-tips text-red">Please input</span>
          </div>
          <div class="extension option relative">
            <div class="option">
              <input type="text" v-model="form.extension" class="input right">
              <div class="label absolute">Extension</div>
            </div>
          </div>
        </div>
        
        <!-- 邮箱 -->
        <div class="row">
          <div class="email-address option relative">
            <div class="option">
              <input type="text" v-model.trim="form.email" placeholder="" class="input" @focus="validErr('email')" @blur="validErr('email')" :class="[errorTip.email ? 'border-red' : '']">
              <div class="label absolute">Email Address <span class="text-red">*</span></div>
            </div>
            <span v-if="errorTip.email" class="error-tips text-red">Please input</span>
          </div>
        </div>
      </div>
      
      <div class="section">
        <div class="agree-box flex">
          <div class="checked">
            <el-checkbox v-model="isChecked"></el-checkbox>
          </div>
          <div class="agree-text" @click="toSwitchAgree">I agree to share my contact information with the relevant suppliers for business matching. </div>
        </div>
        <div v-if="!isChecked" class="error-tips text-red">Please select</div>
      </div>
      
      <button class="submit-btn" @click="submit">Submit</button>
    </div>
    
    <custom-footer :friendLink="friendLink"></custom-footer>
    
    <!-- 回到顶部 -->
    <scroll-top @scrollTop="scrollTop"></scroll-top>
    
    <!-- <div v-if="isShowMask" class="mask-layer"></div> -->
    
  </div>
</template>

<script>
import { Toast } from 'vant';
import customHeader from "./components/head.vue";
import customFooter from "./components/foot.vue";
import scrollTop from "./components/scrollTop.vue";
import commonMixin from './commonMixin.js';
import countryList from '../utils/countrys.js';
import { getCountryList, submitSourcing } from '@/api/index.js';

export default {
  // name: '深圳国际家具展',
  components: {
    'custom-header': customHeader,
    'custom-footer': customFooter, 'scroll-top': scrollTop,
    // [Toast.name]: Toast,
  },
  mixins: [commonMixin],
  metaInfo: {
    title: 'Shenzhen World Furniture Exhibition: Sourcing Request Form', // set a title
    meta: [
      { name: 'keyWords', content: 'Furniture Fair,Home Design,Furniture Exhibitor,Home Furnishing Forum,Design Week,Home Design Week' },
      { name: 'description', content: 'Submit Sourcing Request for 39th Shenzhen International Furniture Exhibition. Choose the Category, Purpose, and Share Your Contact Info With Relevant Suppliers.'},
    ],
  },
  data() {
    return {
      current: 'sourcing',
      keyword: '',
      isShowFurniture: false,
      isShowDecor: false,
      isShowSmart: false,
      isShow: false, // 是否显示国家地区列表
      countryList: [],
      locationOptions: [], // 国家列表
      isShowSexList: false,
      isShowCountryList: false,
      isChecked: true,
      error: {
        looking: false,
        checked1: false,
        checked2: false,
        checked3: false,
      },
      looking: [],
      checked1: [],
      plsClarify1: '',
      checked2: [],
      plsClarify2: '',
      checked3: [],
      plsClarify3: '',
      form: {
        sourcingPurpose: '', // 采购目的
        sex: '',
        name: '',
        companyName: '',
        country: '',
        countryCode: '',
        areaCode: '',
        tel: '',
        extension: '',
        email: '',
      },
      errorTip: {
        sourcingPurpose: false,
        sex: false,
        name: false,
        companyName: false,
        country: false,
        countryCode: false,
        tel: false,
        email: false,
      }
    }
  },
  created() {
    this.getCountrys();
  },
  methods: {
    showSexList() {
      this.isShowCountryList = false;
      this.isShowSexList = true;
    },
    sexChange(sex) {
      this.form.sex = sex;
      this.isShowSexList = false;
      this.errorTip.sex = false;
    },
    closeSelectList() {
      this.isShowSexList = false;
      this.isShowCountryList = false;
    },
    onChange(vals) {
      if (vals.includes('Furniture')) {
        this.isShowFurniture = true
      } else {
        this.isShowFurniture = false;
        this.error.checked1 = false;
        this.checked1 = [];
        this.plsClarify1 = '';
      }
      if (vals.includes('Decor')) {
        this.isShowDecor = true
      } else {
        this.error.checked2 = false;
        this.isShowDecor = false;
        this.checked2 = [];
        this.plsClarify2 = '';
      }
      if (vals.includes('Smart')) {
        this.isShowSmart = true
      } else {
        this.isShowSmart = false;
        this.error.checked3 = false;
        this.checked3 = [];
        this.plsClarify3 = '';
      }
      
      this.error.looking = vals.length == 0;
    },
    onChange1(vals) {
      this.error.checked1 = this.checked1.length == 0 && this.plsClarify1 == '';
    },
    onChange2(vals) {
      this.error.checked2 = this.checked2.length == 0 && this.plsClarify2 == '';
    },
    onChange3(vals) {
      this.error.checked3 = this.checked3.length == 0 && this.plsClarify3 == '';
    },
    validErr(key) {
      this.errorTip[key] = !this.form[key];
    },
    // 显示国家地区列表
    showCountryList() {
      if (document.documentElement.scrollTop > 300) {
        this.isTop = false;
      } else{
        this.isTop = true;
      }
      this.isShowSexList = false;
      this.isShowCountryList = true;
      this.keyword = '';
      this.locationOptions = this.countryList;
    },
    toSwitchAgree() {
      this.isChecked = !this.isChecked;
    },
    // 输入搜索
    inputSearch() {
      this.locationOptions = this.keyword ? this.countryList.filter( e => e.area_name.toLowerCase().includes(this.keyword.toLowerCase())) : this.countryList;
    },
    // 国家地区列表选择事件
    chooseLocation(e) {
      // let {code, name} = e.currentTarget.dataset;
      let {code, name} = e.target.dataset;

      this.form.countryCode = code; // 电话区号
      this.form.country = name; // 国家名称
      this.isShowCountryList = false;
      this.errorTip.country = false;
    },
    getCountrys() {
      // getCountryList().then(res => {
      //   if(res.code == 200) {
      //     let countryList = res.data || [];
      //     this.countryList = Object.freeze(countryList);
      //     this.locationOptions = countryList;
      //   }
      // })
      this.countryList = Object.freeze(countryList);
      this.locationOptions = countryList;
    },
    submit() {
      if (this.looking.length == 0) {
        this.error.looking = true;
        this.$refs['body'].scrollIntoView({ behavior: "smooth" });
        return;
      }
      if (this.looking.includes('Furniture') && this.checked1.length == 0 && this.plsClarify1 == '') {
        this.error.checked1 = true;
        this.$refs['body'].scrollIntoView({ behavior: "smooth" });
        return;
      }
      if (this.looking.includes('Decor') && this.checked2.length == 0 && this.plsClarify2 == '') {
        this.error.checked2 = true;
        this.$refs['body'].scrollIntoView({ behavior: "smooth" });
        return;
      }
      if (this.looking.includes('Smart') && this.checked3.length == 0 && this.plsClarify3 == '') {
        this.error.checked3 = true;
        this.$refs['body'].scrollIntoView({ behavior: "smooth" });
        return;
      }

      for (let [k,v] of Object.entries(this.form)) {
        if (!['areaCode', 'extension'].includes(k) && !v) {
          this.errorTip[k] = true;
          return;
        }
      }
      
      let plsClarify = this.plsClarify1;
      if (this.plsClarify2) {
        plsClarify += '; ' + this.plsClarify2
      }
      if (this.plsClarify3) {
        plsClarify += '; ' + this.plsClarify3
      }
      let data = {
        looking: this.looking.join(', '),
        productCategory: [...this.checked1, ...this.checked2, ...this.checked3].join(', '),
        plsClarify,
        ...this.form,
      };
      
      submitSourcing(data).then(res => {
        if (res.resultStatus) {
          Toast('Submitted successfully')
        } else{
          Toast('Failed to submit')
        }
      })

    }
  },
}
</script>
<style>
@media screen and (min-width: 1024px) {
  .van-toast {
    padding: 3px; 
    font-size: 6px;
    line-height: 2;
  }
}
</style>
<style scoped lang="less">
.text-red {
  color: red;
}
.mask-layer {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 3;
  background-color: rgba(0, 0, 0, .2);
  pointer-events: none;
}

// 移动端
@media screen and (max-width: 1023px) {

  .content-container-form {
    width: 375px;
    margin: 10px auto 20px;
    padding: 0 14px;
    
    h1 {
      font-size: 17px;
      text-align: center;
      // font-family: 'Poppins-Bold';
      font-family: 'Poppins-Medium';
      margin-bottom: 10px;
    }
    
    .tips {
      font-size: 14px;
      text-align: center;
      margin-bottom: 20px;
    }
    
    .other-box {
      display: flex;
      align-items: center;
      margin-bottom: 5px;
      font-size: 14px;
      color: #606266;
      
      .other-input {
        border-radius: 4px;
        border: 1px solid #DCDFE6;
        padding: 0 5px;
        box-sizing: border-box;
        width: 170px;
        height: 28px;
        margin-left: 10px;
        font-size: 14px;
      }
    }
    
    /deep/ .el-checkbox {
      margin-right: 15px;
      margin-bottom: 5px;
    }
    
    .textarea {
      border: .5px solid #DCDFE6;
      padding: 3px 8px;
      margin: 2px 0;
      box-sizing: border-box;
      width: 100%;
      height: 70px;
      font-size: 14px;
      color: #606266;
    }
    

    .agree-box {
      align-items: flex-start;
      
      .agree-text {
        font-size: 13px;
        line-height: 1.5;
        cursor: pointer;
      }
    }
    
    .section {
      margin-bottom: 18px;
      font-size: 14px;
    }
    .title {
      font-size: 13px;
      font-family: 'Poppins-Bold';
      margin-bottom: 5px;
    }
    
    .sub-title {
      font-size: 14px;
      margin: 2px 0;
    }
    .row {
      margin: 18px 0;
      
      .trun-icon {
        top: 7px;
        right: 10px;
        z-index: 1;
        display: block;
        width: 16px;
        height: 16px;
        font-size: 16px;
        text-align: center;
        line-height: 16px;
        transform: rotate(90deg);
      }
      
      .input {
        border: 1px solid #DCDFE6;
        padding: 5px 10px 0;
        box-sizing: border-box;
        width: 100%;
        height: 30px;
        font-size: 13.5px;
      }
      
      .error-tips {
        font-size: 13px;
      }
      
      .option {
        
        .label {
          top: -10px;
          left: 10px;
          z-index: 2;
          cursor: text;
          padding: 0 2px;
          font-size: 13px;
          background-color: #fff;
          color: #999;
          pointer-events: none;
        }
        
      }
    }
    
    .salutation,
    .name,
    .company-name,
    .country-name,
    .email-address {
      width: 345px;
    }
    
    .salutation,
    .country-code,
    .area-code,
    .tel,
    .extension {
      margin-bottom: 20px;
    }
    
    .salutation {
      
      .sex-list {
        top: 29px;
        left: 0;
        z-index: 5;
        width: 345px;
        height: 80px;
        overflow: hidden;
        padding: 0;
        background-color: #fff;
        border: 1px solid #DCDFE6;
        
        .sex-option {
          font-size: 14px;
          line-height: 1.8;
          padding: 0 5px;
        }
        
      }
    }
    
    .country-list-box {
      width: 345px;
      left: 0;
      z-index: 5;
      background-color: #fff;
      border: 1px solid #DCDFE6;
      box-shadow: 0 2px 6px rgba(0, 0, 0, .2);
      
      &.top {
        top: -248px;
      }
      &.down {
        top: 30px;
      }
      
      .search-input {
        border: 1px solid #DCDFE6;
        padding: 0 8px;
        box-sizing: border-box;
        width: 333px;
        height: 28px;
        font-size: 13px;
        margin: 5px;
      }
      
      .country-list {
        width: 100%;
        height: 200px;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
        padding: 5px 10px;
        
        .country-option {
          font-size: 13px;
          line-height: 2;
          padding: 0 2px;
        }
        
      }
      
    }
    
    .border-red {
      border: 0.5px solid red!important;
    }
    .border-white {
      border: 0.5px solid #fff!important;
    }
    
    .submit-btn {
      display: block;
      background-color: #409eff;
      border: none;
      color: #fff;
      font-size: 15px;
      border-radius: 4px;
      padding: 5px 30px;
      margin: 30px auto;
      cursor: pointer;
    }
  }
  
  
}

// pc
@media screen and (min-width: 1024px) {
.checked3 {
  margin-left: -1px;
}
.hand {
  cursor: pointer;
}

  .content-container-form {
    width: 240px;
    margin: 0 auto 20px;
    padding: 10px 0;
    
    h1 {
      font-size: 5.6px;
      text-align: center;
      // font-family: 'Poppins-Bold';
      font-family: 'Poppins-Medium';
      margin-bottom: 2px;
    }
    
    .tips {
      font-size: 4px;
      text-align: center;
      margin-bottom: 8px;
    }
    
    .other-box {
      font-size: 4px;
      color: #606266;
      margin-left: 1px;
      
      .other-input {
        border-radius: 1px;
        border: .5px solid #DCDFE6;
        padding: 2px;
        box-sizing: border-box;
        width: 145px;
        height: 10px;
        margin-left: 5px;
        font-size: 4px;
      }
    }
    
    .textarea {
      border: .5px solid #DCDFE6;
      padding: 2px;
      margin: 2px 0;
      box-sizing: border-box;
      width: 240px;
      height: 35px;
      font-size: 4px;
      color: #606266;
    }
    
    /deep/ .el-checkbox-group {
      display: flex;
      flex-wrap: wrap;
      padding: 0;
      margin-bottom: 3px;
      
      .el-checkbox {
        transform: scale(.7);
        display: flex;
        align-items: center;
        
        font-size: 5px;
        margin: 2px 5px 0 -4px;
        
        .el-checkbox__label {
          padding-left: 5px;
          line-height: 1.1;
          font-size: 4.8px;
        }
        
        .el-checkbox__input {
          &.is-checked .el-checkbox__inner {
            background-color: #409eff;
            border-color: #409eff;
          }
          .el-checkbox__inner {
            border: 0.5px solid #DCDFE6;
            border-radius: 1px;
            width: 7px;
            height: 7px;

            &:after {
              height: 4px;
              left: 1.6px;
              top: 0;
              width: 2px;
            }
          }
        }
        
      }
      
    }
    
    .agree-box {
      align-items: flex-start;
      
      .checked {
        transform: scale(.6);
        
        /deep/ .el-checkbox {
          margin: 0;
          padding: 0;
          font-size: 3.8px;;
          
          .el-checkbox__input {
            &.is-checked .el-checkbox__inner {
              background-color: #409eff;
              border-color: #409eff;
            }
            .el-checkbox__inner {
              border: 0.5px solid #DCDFE6;
              border-radius: 1px;
              width: 7px;
              height: 7px;

              &:after {
                height: 4px;
                left: 1.6px;
                top: 0;
                width: 2px;
              }
            }
          }
        }
        
      }
      
      .agree-text {
        font-size: 3.8px;;
        margin-left: 3px;
        line-height: 1.8;
        cursor: pointer;
      }
    }
    
    .section {
      margin-bottom: 8px;
      font-size: 3.8px;
    }
    .title {
      font-size: 3.8px;
      font-family: 'Poppins-Bold';
    }
    
    .sub-title {
      font-size: 3.2px;
      margin: 2px 0;
    }
    .row {
      display: flex;
      margin: 8px 0;
      
      .trun-icon {
        top: 1px;
        right: 2px;
        z-index: 1;
        display: block;
        width: 10px;
        height: 10px;
        font-size: 7px;
        text-align: center;
        line-height: 10px;
        transform: rotate(90deg);
      }
      
      .input {
        border: .5px solid #DCDFE6;
        padding: 2px 5px 0;
        box-sizing: border-box;
        width: 100%;
        height: 12px;
        font-size: 4px;
        
        &.left {
          border-right: none;
        }
        &.right {
        }
        &.center {
          border-radius: 0;
          border-right: none;
        }
      }
      
      .error-tips {
        font-size: 3.6px;
      }
      
      .option {
        
        .label {
          top: -2px;
          left: 4px;
          z-index: 2;
          cursor: text;
          padding: 0 2px;
          font-size: 3.4px;
          background-color: #fff;
          color: #999;
          pointer-events: none;
        }
        
      }
    }
    
    .salutation {
      width: 90px;
      
      .sex-list {
        top: 12px;
        left: 0;
        z-index: 5;
        width: 90px;
        height: 25px;
        overflow: hidden;
        padding: 1px 0;
        background-color: #fff;
        border: 0.5px solid #DCDFE6;
        
        .sex-option {
          font-size: 4.5px;
          line-height: 1.6;
          padding: 0 3px;
          
          &.hover:hover {
            cursor: pointer;
            background-color: #409eff;
            color: #fff;
          }
        }
        
      }
    }
    
    .country-list-box {
      width: 240px;
      top: 12px;
      left: 0;
      z-index: 5;
      background-color: #fff;
      border: 0.5px solid #DCDFE6;
      
      .search-input {
        border: .5px solid #DCDFE6;
        padding: 2px;
        box-sizing: border-box;
        width: 235px;
        height: 9px;
        font-size: 4px;
        margin: 2px;
      }
      
      .country-list {
        width: 100%;
        height: 60px;
        overflow-y: auto;
        padding: 1px 2px;
        
        
        .country-option {
          font-size: 4.5px;
          line-height: 2;
          padding: 0 2px;
          
          &:hover {
            cursor: pointer;
            background-color: #409eff;
            color: #fff;
          }
        }
        
      }
      
    }
    .name {
      width: 145px;
      margin-left: 5px;
    }
    .company-name,
    .country-name,
    .email-address {
      width: 240px;
    }
    
    .country-code,
    .area-code,
    .tel,
    .extension {
      flex: 1;
    }
    
    .border-red {
      border: 0.5px solid red!important;
    }
    .border-white {
      border: 0.5px solid #fff!important;
    }
    
    .submit-btn {
      display: block;
      background-color: #409eff;
      border: none;
      color: #fff;
      font-size: 5px;
      border-radius: 1px;
      padding: 2px 10px;
      margin: 10px auto;
      cursor: pointer;
    }
  }
  
}

</style>
